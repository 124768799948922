import React from 'react';

const UnderConstruction = () => {
  return (
    <>
      <section className="container ea-section--full">
        <h3 className="ea-title mb-3">Stay tuned</h3>
        <h1 className="ea-heading-one mb-8">
          Website <br />
          Coming soon
        </h1>
        <p>We are working on our website. We will soon be launching it.</p>
      </section>
    </>
  );
};

export default UnderConstruction;
