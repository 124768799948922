import logo from './logo.svg';
import './sass/main.scss';
import Header from './components/header';
import UnderConstruction from './pages/underConstruction';

const App = () => {
  return (
    <div className="App">
      <Header />
      <UnderConstruction />
    </div>
  );
};

export default App;
